import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const sliderStyles = {
  width: '90%',
  maxWidth: '400px',
  margin: '0 auto',
  borderRadius: '1rem',
  color: '#FFFFFF',
  padding: '2rem',
  boxShadow: '0 0 10px rgba(0,0,0,0.1)',
  
};

const imageStyles = {
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
};

// Custom arrow components
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ 
        ...style, 
        display: "block", 
        background: "white", // Black background
         
        color: "black", // Arrow color
      
      }}
      onClick={onClick}
    >
      <span style={{ fontSize: "24px" }}>&#8250;</span> {/* Right arrow symbol */}
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ 
        ...style, 
        
        background: "white", // Black background
         
        color: "black", // Arrow color
        
        padding: 'auto'
      }}
      onClick={onClick}
    >
      <span style={{ left: "-10px",  fontSize: "24px" }}>&#8249;</span> {/* Left arrow symbol */}
    </div>
  );
}

function GallerySlider() {
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    arrows: false, // Enable navigation arrows
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <section className="work section" id='portfolio'>
     
      <div className="portfoolio">
        <div style={sliderStyles} className="slider-container">
          <Slider {...settings}>
          <div>
              <img src="https://iili.io/JFZCbyu.jpg" alt="Food" style={imageStyles} />
            </div>
            <div>
              <img src="https://iili.io/dXp7IWX.png" alt="Bicycle" style={imageStyles} />
            </div>
            
            <div>
              <img src="https://iili.io/H1oTkDN.jpg" alt="Profile" style={imageStyles} />
            </div>
            <div>
              <img src="https://iili.io/dXpdlGn.png" alt="Sheo" style={imageStyles} />
            </div>
            <div>
              <img src="https://iili.io/H1oTUiX.jpg" alt="Watch" style={imageStyles} />
            </div>
            <div>
              <img src="https://iili.io/H1ouRzN.jpg" alt="Watch2" style={imageStyles} />
            </div>
            <div>
              <img src="https://iili.io/H1oT4ls.jpg" alt="Sheo2" style={imageStyles} />
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default GallerySlider;
