import React from 'react'
import './footer.css'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__container container">
                <h1 className="footer__title">Muhammad Umar</h1>

                <ul className="footer__list">
                    <li>
                        <a href="#about" className="footer__link">About</a>
                    </li>

                    <li>
                        <a href="#portfolio" className="footer__link">Projects</a>
                    </li>

                    <li>
                        <a href="#skills" className="footer__link">Skills</a>
                    </li>
                </ul>

                <div className="footer__social">
                    <a href="https://www.facebook.com/Umar4080/" className="footer__social-link" target="_blank" rel="noreferrer">
                        <i className="bx bxl-facebook"></i>
                    </a>

                    <a href="https://www.instagram.com/design.by.umar" className="footer__social-link" target="_blank" rel="noreferrer">
                        <i className="bx bxl-instagram"></i>
                    </a>

                    <a href="https://wa.link/lr7egr" target="_blank" className="footer__social-link"  rel="noreferrer">
                        <i className="bx bxl-whatsapp"></i>
                    </a>


                    <a href="https://www.linkedin.com/in/umar4080/" className="footer__social-link" target="_blank" rel="noreferrer">
                        <i className="bx bxl-linkedin"></i>
                    </a>

                    <a href="https://t.me/umar_4080" className="footer__social-link" target="_blank" rel="noreferrer">
                        <i className="bx bxl-telegram"></i>
                    </a>

                    <a href="https://join.skype.com/invite/xWNuIRKbviM3" className="footer__social-link" target="_blank" rel="noreferrer">
                        <i className="bx bxl-skype"></i>
                    </a>
                </div>

                <span className='footer__copy'>
                    &#169;M Umar. All rigths reserved
                </span>
            </div>
        </footer>
    )
}

export default Footer