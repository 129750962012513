import React from 'react'

const Social = () => {
    return (
        <div className="home__social">
            <a href="https://www.instagram.com/design.by.umar/" className="home__social-icon" target="_blank" rel="noreferrer">
                <i className="uil uil-skype-alt"></i>
            </a>

            <a href="https://wa.link/lr7egr" className="home__social-icon" target="_blank" rel="noreferrer">
                <i className="uil uil-whatsapp"></i>
            </a>

            <a href="https://www.facebook.com/Umar4080/" className="home__social-icon" target="_blank" rel="noreferrer">
                <i className="uil uil-facebook-f"></i>
            </a>
        </div>
    )
}

export default Social