import React, { useState } from 'react';
import './Swiper.md.css'; // Import custom styles

const ProductCard = () => {
    const [activeTab, setActiveTab] = useState(0);

    const tabs = [
        { title: 'Crypto', icon: 'bx bx-bitcoin', content: 'A Simple Website to check Live Rates of Any Coin or Cryptocurrency.' },
        { title: 'Games', icon: 'bx bxs-joystick', content: 'I have created Some Games. Please Check and give your feedback.' },
    ];

    const gameLinks = [
        { title: 'Chess', url: 'https://crypto.itsumar.xyz//games/chess1/' },
        { title: 'Stick Hero', url: 'https://crypto.itsumar.xyz//games/stick-hero/' },
        { title: 'Tic Tac', url: 'https://crypto.itsumar.xyz//games/tik-tac.html' },
        { title: 'Memory Card', url: 'https://crypto.itsumar.xyz//games/memory.html' },
        // { title: 'Target Game', url: 'https://www.example.com/game5' },
        // { title: 'Game 6', url: 'https://www.example.com/game6' },
    ];

    const openLink = (url) => {
        window.open(url, '_blank');
    };

    return (
        <div className="tab-view">
            <div className="tab-header">
                {tabs.map((tab, index) => (
                    <div
                        key={index}
                        className={`tab-header-item ${activeTab === index ? 'active' : ''}`}
                        onClick={() => setActiveTab(index)}
                    >
                        <i className={tab.icon}></i>
                        <span>{tab.title}</span>
                    </div>
                ))}
            </div>
            <div className="tab-content">
                <p>{tabs[activeTab].content}</p>

                {activeTab === 0 && (
                    <button onClick={() => openLink('https://crypto.itsumar.xyz//')} className="open-link-button">
                        Open  
                        <i className="bx bx-link-external" style={{ marginLeft: '5px' }}></i>
                    </button>
                )}

                {activeTab === 1 && (
                    <div className="game-buttons-grid">
                        {gameLinks.map((game, index) => (
                            <button 
                                key={index} 
                                onClick={() => openLink(game.url)} 
                                className="game-link-button"
                            >
                                {game.title}
                                <i className="bx bx-link-external" style={{ marginLeft: '5px' }}></i>
                            </button>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductCard;
